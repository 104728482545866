<template>
  <div>
    <a-list
      :data-source="items"
      bordered
      item-layout="vertical"
      :pagination="{
        current: currentPage,
        pageSize: pageSize,
        total: items.length,
        onChange: onPageChange
      }"
    >
      <a-list-item
        v-for="(item, index) in paginatedItems"
        :key="item.id"
      >
        <a-list-item-meta
          :title="item.title"
          :description="item.description"
        />
        第{{ item.title }}个
      </a-list-item>
    </a-list>

    <a-button @click="addEvaluation">新增</a-button>
    <a-button @click="onPageChange(2)">跳转到第2页</a-button>
    <a-button @click="onPageChange(3)">跳转到第3页</a-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1, // 当前页码
      pageSize: 1, // 每页显示的条目数
      items: [
        { id: 1, title: '项目 1', description: '描述 1' },
        { id: 2, title: '项目 2', description: '描述 2' },
        { id: 3, title: '项目 3', description: '描述 3' },
        { id: 4, title: '项目 4', description: '描述 4' },
        { id: 5, title: '项目 5', description: '描述 5' },
        { id: 6, title: '项目 6', description: '描述 6' },
        { id: 7, title: '项目 7', description: '描述 7' },
        { id: 8, title: '项目 8', description: '描述 8' },
        { id: 9, title: '项目 9', description: '描述 9' },
        { id: 10, title: '项目 10', description: '描述 10' },
      ],
    };
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.items.slice(start, start + this.pageSize);
    },
  },
  methods: {
    addEvaluation () {
      this.items.push({ id: this.items.length+1, title: '项目 111', description: '描述 1111' })
    },
    onPageChange(page) {
      this.currentPage = page; // 更新当前页
    },
  },
};
</script>

<style scoped>
/* 可根据需要添加样式 */
</style>
